































import { Component, Vue } from 'vue-property-decorator'
import UserApi from '@/api/user'

@Component({
  name: 'Login'
  })
export default class extends Vue {
  private childWindow: Window | any
  private logo = ''
  private width = ''
  private height = ''

  created() {
    this.getLogo()
  }

  private receiveMessage(event:any) {
    if (event.data.id_token && event.data.refresh_token && event.data.state && event.data.token_type) {
      this.childWindow.close()
      location.reload()
    }
  }

  private async getLogo() {
    const { data } = await UserApi.getLogo()
    if (data) {
      this.logo = data.loginLogo
      this.width = data.loginLogoWidth
      this.height = data.loginLogoHeight
      const link = document.querySelector('link[rel="icon"]') as HTMLLinkElement
      if (link && data.favicon) {
        link.href = data.favicon
      }
    }
  }

  private generateNonce() {
    // 创建一个空的数组，用于存储随机数
    const nonceArray: string[] = []
    // 随机生成16个字符
    for (let i = 0; i < 16; i++) {
      // 生成一个0到15之间的随机数
      const randomDigit = Math.floor(Math.random() * 16)
      // 将随机数转换为相应的十六进制字符
      const randomHexChar = randomDigit.toString(16)
      nonceArray.push(randomHexChar)
    }
    // 将数组连接成一个字符串
    return nonceArray.join('')
  }

  login() {
    const screenWidth = window.screen.width
    const screenHeight = window.screen.height

    // 计算窗口的左上角坐标，使其在屏幕上居中
    const left = (screenWidth - 400) / 2
    const top = (screenHeight - 460) / 2
    const current = window.location.href
    const paramStr = `response_type=id_token&client_id=example-client&redirect_uri=${process.env.VUE_APP_DOMAIN}/#/callback&grant_type=qr_code&scope=openid&state=${current}&nonce=${this.generateNonce()}`
    // window.location.href = (`https://did.ecaasospoc.com/api/v1/authorize?${encodeURIComponent(paramStr)}`)
    this.childWindow = window.open(`https://did.ecaasospoc.com/api/v1/authorize?${encodeURIComponent(paramStr)}`, 'authPopup', `width=${400},height=${460},left=${left},top=${top},scrollbars=yes`)
    window.addEventListener('message', this.receiveMessage)
  }
}
